@import "./mixins.scss";

.recommendation-list-container {
  max-height: calc(100vh - 9.5rem);
  overflow: auto;
  @include custom-scroll-bar();
}

@media (min-width: 601px) and (max-width: 1023px) {
  .recommendation-list-container {
    max-height: calc(100vh - 155px);
  }
}

@media (max-width: 600px) {
  .recommendation-list-container {
    max-height: calc(100vh - 140px);
  }
}

.search-highlight {
  background: #ffefaf;
}

.recommendation-toggle {
  position: relative;
  .inner {
    z-index: 10;
    &:after {
      position: absolute;
      right: 0.5rem;
      transition: transform 0.3s ease-in-out;
      @include font-icon("\e900");
    }
    &.expanded {
      &:after {
        transform: rotate(180deg);
      }
    }
    &.collapsed {
      &:after {
        transform: rotate(0deg);
      }
    }
  }
}

.recommendation-header-link {
  &:first-child {
    margin-right: 5px;
  }
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  font-size: 14px;
}

.review-category-icon {
  &.evidence-review.grayed > g > path,
  &.guideline-review.grayed {
    fill: #ccc;
    .a,
    .d {
      fill: #ccc;
    }
  }
}
