@import "./constants.scss";

#fake-loader {
  position: relative;
  width: 100%;
  border: 1px solid lighten($gradientStart, 20%);
  #fake-loader__progress-bar {
    padding: 20px;
    width: 0px;
    text-align: center;
    background: transparent
      linear-gradient(90deg, lighten($gradientStart, 20%) 0%, lighten($gradientEnd, 20%) 100%) 0% 0%
      no-repeat padding-box;
  }

  #fake-loader__progress-status {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 9px;
    color: #000;
  }
}
