@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700");

@import "./mixins.scss";
@import "./sidebar.scss";
@import "./recommendation.scss";
@import "./grid.scss";
@import "./fakeLoader.scss";

* {
  box-sizing: border-box;
}

$border-color: #009cca;

$gradientStart: #48b5aa;
$gradientEnd: #273680;
html {
  scroll-behavior: smooth;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  position: relative;
  min-width: 320px;
  overflow: hidden;
}

p {
  margin-bottom: 20px;
}

.logo {
  color: #fff;
  font-style: normal;
  font-weight: 600;
}

.font-roboto {
  font-family: "Roboto";
}

.subpage-header {
  position: relative;
  z-index: 2;
}

.scrollable-page-content {
  position: relative;
  z-index: 1;
  max-height: calc(100vh - 105px);
  @include custom-scroll-bar();
  overflow-y: auto;
}

.main-page-container {
  max-height: calc(100vh - 80px);
  width: 100%;
  @include custom-scroll-bar();
  overflow-y: auto;
}

@media (max-width: 600px) {
  .page-content,
  .main-page-container {
    max-height: calc(100vh - 50px);
    width: 100%;
  }
}

.main-page-container__top {
  min-height: calc(100vh - 420px);
}

aside {
  transition: width 0.3s, height 0.3s;
}

input {
  &:active,
  &:focus {
    outline: none;
  }
}

.icon {
  color: red;
  border: none;
  background: transparent;
  font-size: 16px;
  padding: 0;

  &.search:before {
    @include font-icon("\e905");
  }

  &.menu:before {
    @include font-icon("\e90e");
  }

  &.export:before {
    @include font-icon("\e901");
  }
}

.expand-toggle {
  &:after {
    position: absolute;
    right: 0.5rem;
    transition: transform 0.3s ease-in-out;
    @include font-icon("\e900");
  }
  &.expanded {
    &:after {
      transform: rotate(180deg);
    }
  }
  &.collapsed {
    &:after {
      transform: rotate(0deg);
    }
  }
}

.expandable-card {
  border-radius: 0.5rem;
  transition: border-radius 0.3s ease-in-out;
  background: #224089;
  &.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  ~ .expandable-card__content {
    background: #fff;
    border-left: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}

button:focus {
  outline: none;
}

.group-tabs {
  .group-tab {
    font-size: 13px;
    transition: background-color 0.2s ease-in-out;
    background-color: #2494bd;
    border: 1px solid #2494bd;
    &.current,
    &:hover {
      background-color: #f1ae16;
      border: 1px solid #f1ae16;
    }
    &.current-blue {
      background-color: #2494bd;
      border: 1px solid #2494bd;
    }

    &.group-expanded {
      background-color: #fff;
      border: none;
      border-bottom: 1px solid #eaeaea;
      color: #000;
      &:active {
        background-color: #f1ae16;
      }
    }
  }

  .group-tab-toggle {
    border-radius: 20px;
    transition: all 0.1s ease-in-out;
    &:after {
      position: absolute;
      right: 10px;
      transition: transform 0.3s ease-in-out;
      @include font-icon("\e900");
    }
    &.expanded {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      &:after {
        transform: rotate(180deg);
      }
    }
    &.collapsed {
      &:after {
        transform: rotate(0deg);
      }
    }
  }
}

.custom-table {
  .custom-table__label {
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    flex: 0 0 170px;
    @include breakpoint(sm) {
      flex: 0 0 auto;
      border: none;
      font-weight: bold;
    }
  }

  &:last-child {
    .custom-table__label {
      border-bottom: none;
    }
    .custom-table__value:before {
      border-bottom: none;
    }
    @include breakpoint(sm) {
      .custom-table__value {
        border: none;
      }
    }
  }

  .custom-table__value {
    flex-grow: 1;
    position: relative;

    a {
      color: #009cca;
      &:hover {
        color: darken(#009cca, 10%);
      }
    }

    @include breakpoint(sm) {
      border-bottom: 1px solid $border-color;
    }
    &:before {
      position: absolute;
      content: "";
      display: inline-block;
      width: 150px;
      bottom: 0px;
      left: 0px;
      border-bottom: 1px solid $border-color;
      @include breakpoint(sm) {
        display: none;
      }
    }
  }
}

.tooltip-container {
  max-width: 400px;
}
.expandable-card__content {
  .question-data:not(:last-child) {
    $border-color: #5c91d4;
    border-radius: 0;
    border-bottom: 1px solid $border-color;
  }

  .collapse-arrow {
    padding-top: 10px;
    margin-top: 20px;
    height: 30px;
    text-align: center;
    .collapse-arrow__button {
      cursor: pointer;
      padding: 10px;
      width: 30px;
      height: 30px;
      background: url("../assets/double-arrow.svg") no-repeat 50% 50%;
      background-size: 200px 30px;
      transform: rotate(90deg);
    }
  }
}

.quality-sign {
  font-family: "GRADE-quality";
  display: inline-block;
  vertical-align: center;
  margin-right: 1px;
  height: 15px;
  width: 15px;
  line-height: 15px;
  color: darken(#ffbd29, 10%);
  &.quality-sign--small {
    width: 13px;
    height: 13px;
    line-height: 13px;
  }
}

.search-input {
  height: 40px;
  min-width: 400px;
  border-bottom: 1px solid $border-color;
  padding: 5px 40px 0px 5px;
  color: #000;

  @include breakpoint(sm) {
    min-width: 300px;
    text-overflow: ellipsis;
  }
}

.main-page-container {
  .search-input {
    color: #fff;
    border-bottom: 1px solid #fff;
  }
  .btn-search {
    color: #fff;
  }
}

.main-page-content {
  @include custom-scroll-bar();
}

.social-link {
  display: block;
  padding: 10px;
  line-height: 30px;
  text-align: center;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  &:hover {
    opacity: 0.75;
  }
}

.twitter {
  .logo {
    display: inline-block;
    width: 30px;
    margin-top: -2px;
    margin-right: 15px;
    vertical-align: middle;
    height: 30px;
    background: url("../assets/twitter.svg") 50% 50% no-repeat;
    background-size: 30px 30px;
    &.white {
      background: url("../assets/twitter-white.svg") 50% 50% no-repeat;
    }
  }
}

.btn-clear-search {
  width: 40px;
  height: 40px;
  position: absolute;
  right: -33px;
  top: 4px;
  color: #fccb5c;
  outline: none;
  &:before {
    line-height: 36px;
    font-size: 36px;
    content: "\00d7";
  }
  &:hover {
    opacity: 0.75;
  }
  &:focus {
    outline: none;
  }
  &:active {
    right: -34px;
    top: 3px;
  }
}

.btn-search {
  width: 40px;
  height: 55px;
  position: absolute;
  right: 0px;
  top: -3px;
  color: #007db3;
  outline: none;
  &:before {
    font-size: 20px;
    @include font-icon("\e905");
  }
  &:hover {
    opacity: 0.75;
  }
  &:focus {
    outline: none;
  }
  &:active {
    top: -2px;
    right: -1px;
  }
}

.separator {
  height: 1px;
  background: #b2e0e5;
}

.modal-content {
  margin: -20px;
  padding: 20px;
}

.close-modal {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  &:before {
    content: "\00d7";
    font-size: 26px;
    display: block;
    width: 10px;
    height: 10px;
    color: #666;
  }
}

.btn {
  border: 1px solid #139b9f;
  color: #139b9f;
  border-radius: 6px;
  padding: 3px 5px;
  transition: background 0.3s;
  &:active,
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.75;
  }
}

.recommendations-to-compare {
  margin-top: 20px;
  max-height: calc(100vh - 200px);
  @include custom-scroll-bar();
  overflow: auto;

  .highlight-container {
    min-width: 100px;
  }
  li {
    .btn.highlight {
      color: #fff;
      background: #139b9f;
    }

    &:before {
      flex-shrink: 0;
      margin-right: 10px;
      content: " ";
      display: block;
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 50%;
    }

    &.highlighted {
      background: #fff6e1;
      &:before {
        background: #ffe333;
      }
    }
  }
  li:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }
}

.spinner-img {
  animation: spinner-spin infinite 2s linear;
  pointer-events: none;
}

@keyframes spinner-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav {
  .top-nav-link {
    padding: 3px 0px;
    line-height: 16px;
    border-bottom: 2px solid transparent;
    &.active,
    &:hover {
      border-bottom: 2px white solid;
    }
  }
}

.see_more {
  font-size: 14px;
  color: $border-color;
  position: relative;
  padding-right: 25px;
  &:after {
    position: absolute;
    right: 0px;
    top: 2px;
    content: " ";
    display: inline-block;
    width: 20px;
    height: 15px;
    background: url("../assets/more-arrow.svg") 50% 50% no-repeat;
  }
}

.hamburger-menu {
  position: relative;
  button.open-menu {
    width: 35px;
    height: 18px;
    position: absolute;
    right: 8px;
    z-index: 102;
    background: url("../assets/hamburger.svg") no-repeat 0 0;
    &.open {
      background: url("../assets/hamburger-dark.svg") no-repeat 0 0;
    }
  }
  .menu-container {
    height: calc(100vh);
    position: absolute;
    padding-top: 60px;
    overflow: auto;
    width: 300px;
    right: -10px;
    top: -20px;
    border: 1px solid $border-color;
    background: #fff;
    z-index: 100;
    @include breakpoint(sm) {
      width: calc(100vw);
      right: 0px;
      top: -14px;
    }

    .hamburger-link {
      line-height: 20px;
      display: flex;
      flex-direction: row;
      justify-items: center;
      $size: 25px;
      line-height: $size;
      &:before {
        content: " ";
        margin-right: 10px;
        width: $size;
        height: $size;
        display: block;
        background: url("../assets/double-arrow.svg") 50% 50% no-repeat;
        background-size: $size $size;
        transform: rotate(180deg);
      }
      span {
        display: block;
      }
    }
  }

  .close-menu-arrow {
    width: 75px;
    height: 75px;
    margin-bottom: 20px;
    margin-left: 20px;
    &:before {
      content: " ";
      height: 100%;
      width: 100%;
      display: block;
      background: url("../assets/double-arrow.svg") 50% 50% no-repeat;
      transform: rotate(180deg);
      background-size: 75px 75px;
    }
    @include breakpoint(sm) {
      transform: rotate(270deg);
    }
  }
}

.down-arrow {
  width: 75px;
  height: 75px;
  margin-top: 5px;
  margin-bottom: 20px;
  &:before {
    content: " ";
    height: 100%;
    width: 100%;
    display: block;
    background: url("../assets/double-arrow.svg") 50% 50% no-repeat;
    background-size: 75px 75px;
    transform: rotate(270deg);
  }
}

.main-page-header {
  .hamburger-menu {
    .menu-container {
      top: -13px;
    }
  }
}

.mobile-back-link {
  display: none;
}

.strength-certainty-column {
  min-width: 240px;
  .bordered-info-box:not(:last-child) {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #009cca;
  }
}

.glossary-item {
  p {
    margin-bottom: 10px;
  }
  ul {
    margin: 0px 0px 10px;
    li {
      margin-left: 20px;
      list-style-type: disc;
    }
  }
}

table.glossary {
  margin-top: 30px;
  thead {
    tr {
      border-bottom: 1px solid #009cca;
    }
    th:not(:last-child) {
      border-right: 1px solid #009cca;
    }
  }
  tbody {
    tr:not(:last-child) {
      border-bottom: 1px solid #009cca;
    }
  }

  td,
  th {
    vertical-align: top;
    padding: 10px 20px;
  }

  tbody {
    td:not(:last-child) {
      border-right: 1px solid #009cca;
    }
  }
}

.isof-dx,
.isof-tx {
  margin-left: 10px;
  table.isof-table {
    table-layout: unset !important;
  }
}

#isof-dx-content,
#isof-tx-content {
  .isof {
    .big-number {
      font-size: 26px !important;
    }
  }

  .container.tables {
    margin-bottom: 0px;
    margin-top: 15px;
  }
}

.tailwind-hidden {
  display: none;
}

.conclusions-row {
  .conclusions-row__marker {
    position: relative;
    float: left;
    margin-right: 10px;
    img {
      width: 15px;
      height: 15px;
    }
  }
}

.what-we-are-working-on,
.news-container {
  min-height: 200px;
  background: rgba(127, 127, 127, 0.1);
}

#twitter-testimonials {
  max-width: 1200px;
  margin: 0px auto;
  .tweet-container {
    max-width: 550px;
    margin: 0px auto;
    @include breakpoint(lg) {
      margin-right: 10px;
      width: 290px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.group-filter-btn {
  min-width: 120px;
  padding-left: 20px;
  padding-right: 20px;
}

.gradient-links-bg {
  border-top: 1px solid #fff;
  background: transparent linear-gradient(90deg, #ffffff 0%, #f2f3fd 100%) 0% 0% no-repeat
    padding-box;
}

.external-logo-link {
  flex-shrink: 0;
}

.eml-link {
  display: block;
  width: 42px;
  height: 18px;
  background: url("../assets/eml-small.svg") no-repeat 0 0;
}

.niph-link {
  display: block;
  width: 42px;
  height: 18px;
  background: url("../assets/niph-small.svg") no-repeat 0 0;
}

.love-link {
  display: block;
  width: 42px;
  height: 18px;
  background: url("../assets/love-small.svg") no-repeat 0 0;
}

.info-tooltip-icon {
  display: inline-block;
  position: relative;
  top: 2px;
  width: 15px;
  height: 15px;
  margin-left: 10px;
  background: url("../assets/info-icon.svg") 50% 50% no-repeat;
  background-size: 15px 15px;
}

.ReactModal__Content.ReactModal__Content--after-open.ep-modal.auto {
  position: relative;
  width: auto;
  max-width: 1000px;
}

.logos {
  img {
    min-width: 125px;
  }
}

.guideline {
  .guideline-label {
    min-width: 8rem;
  }
}

.guideline:not(:last-child) .guideline_separator {
  margin-bottom: 40px;
  padding-bottom: 40px;
  margin-left: 100px;
  margin-right: 100px;
  border-bottom: 1px solid #224089;
}
