@mixin font-icon($code) {
  font-family: "eml";
  content: $code;
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
}

@mixin custom-scroll-bar(
  $barWidth: 10px,
  $minBarHeight: 80px,
  $barRadius: 10px,
  $barColor: darkgrey
) {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: $barWidth;
    height: $barWidth;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $barColor;
    min-height: $minBarHeight;
    border-radius: $barRadius;
  }

  & {
    -webkit-overflow-scrolling: touch;
  }
}

@mixin reset-anchor-styles() {
  &,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

@mixin breakpoint($class) {
  @if $class == sm {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $class == md {
    @media (min-width: 600px) and (max-width: 1023px) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $class == xl {
    @media (min-width: 1280px) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: sm, md, lg, xl";
  }
}
